import { useQuery } from "@tanstack/react-query";
import { useParseContext } from "@/context/parseContext";

export const usePendingPostSurveyCount = (user: any) => {
  const Parse = useParseContext();

  const fetchOutstandingPostSurveyCount = async () => {
    if (!user) {
      return 0; // Return 0 or any default value if user is null
    }
    const userPointer = user.toPointer();
    const twoMonthsAgo = new Date();
    twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const fetchedOutstandingPostSurveyCount = await new Parse.Query(
      "SelectedExperiences"
    )
      .equalTo("userId", userPointer)
      .notEqualTo("donePostSurvey", true)
      .greaterThanOrEqualTo("createdAt", twoMonthsAgo)
      .lessThan("createdAt", yesterday)
      .descending("createdAt")
      .limit(10)
      .count();

    return fetchedOutstandingPostSurveyCount <= 10
      ? fetchedOutstandingPostSurveyCount
      : 10;
  };

  const {
    data: fetchedOutstandingPostSurveyCountData,
    isPending: fetchedOutstandingPostSurveyCountIsLoading,
    isError: fetchedOutstandingPostSurveyCountIsError,
    error: fetchedOutstandingPostSurveyCountError,
  } = useQuery({
    queryKey: ["outstandingPostSurvyeCount", user],
    queryFn: fetchOutstandingPostSurveyCount,
    enabled: !!user, // Only run the query if user is not null
  });

  return {
    fetchedOutstandingPostSurveyCountData,
    fetchedOutstandingPostSurveyCountIsLoading,
    fetchedOutstandingPostSurveyCountIsError,
    fetchedOutstandingPostSurveyCountError,
  };
};
