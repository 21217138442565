"use client";
import * as React from "react";
import { useContext } from "react";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import RecPanel from "./RecPanel";
import CompSlidePanel from "./CompSlidePanel";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { object, number, string, ObjectSchema } from "yup";
import UserContext from "@/context/userContext";
import SurveyContext from "@/context/surveyContext";
import ParseContext from "@/context/parseContext";
import SelectedExperContext from "@/context/selectedExperContext";
import CustomExperContext from "@/context/customExperContext";
import SubmittedExperContext from "@/context/submittedExperContext";
import { ExpFormInput } from "@/app/@types/experienceinputobj";

const defaultValues = {
  radioValue: "",
  radioValue2: "",
};

const schema: ObjectSchema<ExpFormInput> = object({
  radioValue: string().required("Please Select a Primary Experience."),
  radioValue2: string().defined(),
});
/**
 * A React component to facilitate the selection of the primary and secondary
 * recommended YEX experiences.
 *
 * @remarks
 * This is the parent component to hold the primary and secondary experience
 * selection panel child compoenents, RecPanel, RecPannelInner, CompSlidePanel,
 * CompSlideInner, and Experience.  The experience options are calculated based
 * upon the survey answers provided by the end user.  The component uses the
 * React Context API to access the YEX user ID, the Parse DB connection, and
 * to store the selected experience.  The component also uses the React Hook Form
 * library to handle the form data.
 *
 * @returns - The RecCompForm React component in JSX.
 */
export const RecCompForm = () => {
  const { user } = useContext(UserContext);
  const { survey } = useContext(SurveyContext);
  const parse = useContext(ParseContext);
  const { setSelectedExper } = useContext(SelectedExperContext);
  const { setCustomExper } = useContext(CustomExperContext);
  const { setSubmittedExper } = useContext(SubmittedExperContext);

  const createExperience = (experienceId: string, isPrimary: boolean) => {
    const experience = new parse.Object("Experiences");
    experience.set("objectId", experienceId);
    const surveyObj = new parse.Object("Surveys");
    surveyObj.set("objectId", survey);

    const selectedExperience = new parse.Object("SelectedExperiences");
    selectedExperience.set("userId", user);
    selectedExperience.set("experienceId", experience);
    selectedExperience.set("primaryExperience", isPrimary);
    selectedExperience.set("surveyId", surveyObj);

    return selectedExperience;
  };

  const persistExperience = async function (
    data: ExpFormInput
  ): Promise<boolean> {
    let SelectedExperience1, SelectedExperience2;

    if (data.radioValue) {
      SelectedExperience1 = createExperience(
        data.radioValue.split(":")[1],
        true
      );
    }

    if (data.radioValue2) {
      SelectedExperience2 = createExperience(
        data.radioValue2.split(":")[1],
        false
      );
    }

    try {
      const promises = [];
      if (SelectedExperience1) promises.push(SelectedExperience1.save());
      if (SelectedExperience2) promises.push(SelectedExperience2.save());

      promises.push(
        fetch("/api/send", {
          method: "POST",
          body: JSON.stringify({
            experience1: data.radioValue.split(":")[0],
            experience2: data.radioValue2 ? data.radioValue2.split(":")[0] : "",
            email: user.get("username"),
          }),
        })
      );

      await Promise.all(promises);

      setSelectedExper(data);

      return true;
    } catch (error: any) {
      console.log(`Error! ${error.message}`);
      methods.setError("radioValue", {
        type: "server",
        message: error.message,
      });
      return false;
    }
  };

  const methods = useForm<ExpFormInput>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onSubmit: SubmitHandler<ExpFormInput> = (data: ExpFormInput) => {
    persistExperience(data);
    setSubmittedExper(true);
  };

  return (
    <FormProvider {...methods}>
      <Box component="form">
        <RecPanel />
        <CompSlidePanel />
        <Stack spacing={2}>
          <Button
            onClick={methods.handleSubmit(onSubmit)}
            fullWidth
            variant="contained"
          >
            Submit My Selected Experiences
          </Button>

          <Button
            onClick={() => {
              setCustomExper(true);
            }}
            fullWidth
            variant="contained"
            sx={{ fontSize: 24 }}
          >
            I would prefer to select my own experience(s).
          </Button>
        </Stack>
      </Box>
    </FormProvider>
  );
};
