"use client";

import * as React from "react";
import { useContext } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import UserContext from "@/context/userContext";
import ParseContext from "@/context/parseContext";
import Link from "next/link";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLogin } from "@/hooks/useLogin";
import { UseMutationResult } from "@tanstack/react-query";

interface IFormInput {
  yexid: string;
  yexpwd: string;
}

/**
 * A React component to read in and verify the existance of the YEX user via password.
 *
 * @remarks
 * This component uses the React Context API (useContext) to persist the YEX user ID and
 * to retrieve the Parse database connection.  The component also uses the React Hook Form
 * library to handle the form data, and the Yup data validation library to check the user ID
 * and password.
 *
 * @param props - React component props not currently used.
 * @returns the SignIn React component in JSX
 */
export default function SignIn() {
  const { setUser } = useContext(UserContext);
  const Parse = useContext(ParseContext);

  const defaultValues = {
    yexid: "",
    yexpwd: "",
  };

  const schema = yup.object().shape({
    yexid: yup.string().email().required("Please enter your email address."),
    yexpwd: yup.string().min(8).max(32).required("Please enter your password."),
  });

  const loginMutation = useLogin();

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IFormInput> = (data: IFormInput) => {
    // console.log(data);
    loginMutation.mutate(data, {
      onSuccess: (data) => {
        setUser(data);
        return true;
      },
      onError: (error) => {
        console.log(`Error! ${error.message}`);
        setError("yexpwd", {
          type: "server",
          message: error.message,
        });
        return false;
      },
    });
  };

  return (
    <Container
      sx={{
        marginTop: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Avatar sx={{ mt: 20, mb: 1, bgcolor: "primary.main" }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <Box component="form" sx={{ mt: 1 }}>
        <Controller
          name="yexid"
          control={control}
          render={({
            field: { onChange, value },
            fieldState: { error },
            formState,
          }) => (
            <TextField
              helperText={error ? error.message : null}
              error={!!error}
              onChange={onChange}
              value={value ?? ""}
              margin="normal"
              required
              fullWidth
              id="yexid"
              label="Please Log In With Your Yamaha Email"
              name="yexid"
              autoComplete="off"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />

        <Controller
          name="yexpwd"
          control={control}
          render={({
            field: { onChange, value },
            fieldState: { error },
            formState,
          }) => (
            <TextField
              helperText={error ? error.message : null}
              error={!!error}
              onChange={onChange}
              value={value ?? ""}
              margin="normal"
              required
              fullWidth
              id="yexpwd"
              label="Please Enter Your YEX Password"
              name="yexpwd"
              type="password"
              autoComplete="off"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />

        <Button
          onClick={handleSubmit(onSubmit)}
          fullWidth
          variant="contained"
          disabled={loginMutation.isPending}
          sx={{ mt: 3, mb: 2 }}
        >
          Sign In
        </Button>
        <Grid container>
          <Grid item xs>
            <Typography variant="body2" color="text.secondary" align="left">
              <Link href="/reset" style={{ textDecoration: "underline" }}>
                Forget Password?
              </Link>
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body2" color="text.secondary" align="right">
              <Link href="/register" style={{ textDecoration: "underline" }}>
                {"New user? Please create an account."}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
