"use client";

import * as React from "react";
import { useState, useEffect, useContext } from "react";
import Paper from "@mui/material/Paper";
import FormLabel from "@mui/material/FormLabel";
import { QuestionFormLabel } from "./QuestionFormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Controller } from "react-hook-form";
import { QuestionObj } from "@/app/@types/questionobj";
import QuestionsContext from "@/context/questionsContext";
import randomIntFromInterval from "@/app/util/randomIntfromInterval";

import createTypography from "@mui/material/styles/createTypography";

interface FormInputProps {
  /**
   * Interface to describe the props used bye the QuestionForm React Component.
   *
   * @param name - String describing the form input type for React Hook Form.
   * @param control - Controller for React Hook Form.
   * @param label - String to be used to label the form component.
   * @param qcategory - String describing the one of four question categories.
   * @param setValue - Optional value param.
   */
  name: string;
  control: any;
  label: string;
  qcategory: string;
  setValue?: any;
}
/**
 * A React component to hold one of four questions in the parent SurveyForm component.
 *
 * @remarks
 * Given the category for this question slot (Stress Releif, Physical Fatigue Relief,
 * Mental Relaxation, or Improve Focus), filter out the questions for the chosen category
 * and select a random question from this category to display.  This component uses the
 * React Context API to retrieve the questions, and React Hook Form to handle the form
 * data.
 *
 * @param param0
 * @returns
 */
export const QuestionForm: React.FC<FormInputProps> = ({
  name,
  control,
  label,
  qcategory,
}) => {
  const [questions, setQuestions] = useState<QuestionObj[]>([]);
  const fetchedQs = useContext(QuestionsContext);

  useEffect(() => {
    function fetchQuestions() {
      // console.log(fetchedQs)
      // console.log(category);
      let filteredQs = fetchedQs.filter(
        (fetchedQ) => fetchedQ.category === qcategory
      );
      setQuestions(filteredQs);
      // console.log("In fetchQuestions");
      // console.log(filteredQs);
    }

    fetchQuestions();
    console.log("In QuestionComponent");
    console.log("qcategory", qcategory);
    console.log("fetchedQs", fetchedQs);
    console.log("questions", questions);

    // eslint-disable-next-line
  }, [fetchedQs, qcategory]);

  const questionsIndex = randomIntFromInterval(0, questions.length - 1);

  return (
    <Paper
      sx={{ bgcolor: "secondary.light", color: "secondary.dark" }}
      elevation={3}
      square={false}
    >
      <FormControl component="fieldset">
        <FormLabel
          id={"radio-buttons-group-label-" + qcategory}
          sx={{ color: "black", "&, &.Mui-focused": { color: "black" } }}
        >
          <QuestionFormLabel question={questions[questionsIndex]?.question} />
        </FormLabel>
        <Controller
          name={name}
          control={control}
          render={({
            field: { onChange, value },
            fieldState: { error },
            formState,
          }) => (
            <RadioGroup
              aria-labelledby={"radio-buttons-group-label-" + qcategory}
              name={"radio-buttons-group-" + qcategory}
              value={value}
              onChange={onChange}
              row
            >
              <FormControlLabel
                value={"yes:" + qcategory + ":" + questions[questionsIndex]?.id}
                control={
                  <Radio
                    sx={{
                      color: "black",
                      "&, &.Mui-checked": { color: "black" },
                    }}
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                value={"no:" + qcategory + ":" + questions[questionsIndex]?.id}
                control={
                  <Radio
                    sx={{
                      color: "black",
                      "&, &.Mui-checked": { color: "black" },
                    }}
                  />
                }
                label="No"
              />
            </RadioGroup>
          )}
        />
      </FormControl>
    </Paper>
  );
};
