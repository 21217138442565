import { useMutation } from "@tanstack/react-query";
import { useParseContext } from "@/context/parseContext";

interface IFormInput {
  yexid: string;
  yexpwd: string;
}

export const useLogin = () => {
  const Parse = useParseContext();

  const mutateLogin = async (data: IFormInput) => {
    await Parse.User.logIn(data.yexid, data.yexpwd);
    return Parse.User.current();
  };

  const mutation = useMutation({
    mutationFn: (data: IFormInput) => mutateLogin(data),
  });

  // mutation.mutate(data);
  return mutation;
};
