"use client";
import * as React from "react";
import { useContext } from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import SelectedExper from "./SelectedExper";
import { Controller, useFormContext } from "react-hook-form";
import ExperienceContext from "@/context/experienceContext";
import SelectedExperContext from "@/context/selectedExperContext";
import UserContext from "@/context/userContext";
import SurveyDataContext from "@/context/surveyDataContext";
import SurveyContext from "@/context/surveyContext";
import { ExperienceObj } from "@/app/@types/experienceobj";
import CustomExperContext from "@/context/customExperContext";
import SubmittedExperContext from "@/context/submittedExperContext";

/**
 * A React component to display/confirm the selected YEX experience(s).
 *
 * @remarks
 * This component displayes the confirmed YEX experience(s). It uses the React
 * Context API to reset the selected experiences, the user ID, and selected
 * experiences back to null so that the sign in page is displayed once the
 * "Sign Out" button is pressed.  This component is a parent component to the
 * SelectedExper component that holds the individual experience(s).
 *
 * @param props - React props not currently used.
 * @returns - The SelectedExperPanel React component in JSX.
 */

export default function SelectedExperPanel(props: any) {
  const { selectedExper, setSelectedExper } = useContext(SelectedExperContext);
  const { setUser } = useContext(UserContext);
  const { setSurveyData } = useContext(SurveyDataContext);
  const { setSurvey } = useContext(SurveyContext);
  const { setCustomExper } = useContext(CustomExperContext);
  const { setSubmittedExper } = useContext(SubmittedExperContext);

  const experiences = useContext(ExperienceContext);

  const exArray: ExperienceObj[] = [
    {
      id: "",
      category: "",
      name: "",
      use: "",
      results: "",
      productWarnings: "",
      productUsageAdvisory: "",
      image: "",
    },
    {
      id: "",
      category: "",
      name: "",
      use: "",
      results: "",
      productWarnings: "",
      productUsageAdvisory: "",
      image: "",
    },
  ];

  const expObj1 = experiences.filter((obj) => {
    return obj.id === selectedExper.radioValue.split(":")[1];
  });
  const expObj2 = experiences.filter((obj) => {
    return obj.id === selectedExper.radioValue2.split(":")[1];
  });

  let dispExper = <SelectedExper {...expObj1[0]} />;

  if (selectedExper == null) {
    dispExper = (
      <>
        <SelectedExper {...exArray[0]} />
      </>
    );
  } else if (selectedExper.radioValue2 != "") {
    dispExper = (
      <>
        <Grid
          container
          alignItems="stretch"
          spacing={3}
          style={{ marginTop: "20px" }}
        >
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <SelectedExper {...expObj1[0]} />
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <SelectedExper {...expObj2[0]} />
          </Grid>
        </Grid>
      </>
    );
  }
  return (
    <Stack spacing={1} alignItems="center" sx={{ mt: 2 }}>
      <Paper
        sx={{ bgcolor: "rgba(0,0,0,0.25)", color: "secondary.dark" }}
        elevation={3}
        square={false}
      >
        <Stack spacing={1}>
          <Typography sx={{ fontSize: 24 }}>
            Thank you! You have been confirmed for the following experience(s):
          </Typography>

          <Paper sx={{ padding: 3 }}>
            <Container>
              <Stack spacing={2}>
                <Stack direction="row" spacing={2} alignItems="center">
                  {dispExper}
                </Stack>
                <Stack alignItems="center" spacing={5}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setUser(null);
                      setSurveyData(null);
                      setSurvey("");
                      setSelectedExper(null);
                      setCustomExper(false);
                      setSubmittedExper(false);
                    }}
                  >
                    Sign Out
                  </Button>
                </Stack>
              </Stack>
            </Container>
          </Paper>
        </Stack>
      </Paper>
    </Stack>
  );
}
