"use client";

import * as React from "react";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

/**
 * A React popover component to display the expected results from a YEX experience.
 *
 * @remarks
 * The component displays the expected experience results when the "Learn More" button
 * is pushed.
 *
 * @param results - String describing the indended experience results.
 * @returns - The BasicPopover React component in JSX.
 */
export default function BasicPopover({
  results,
  productWarnings,
  productUsageAdvisory,
}: any) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="contained"
        size="large"
        onClick={handleClick}
      >
        Learn More
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ m: 3 }}
      >
        <Typography sx={{ p: 2 }} variant="h5" fontWeight="bold">
          Intended Results
        </Typography>
        <Typography sx={{ pl: 2, pb: 2, pr: 2 }}>{results}</Typography>
        {productWarnings && (
          <>
            <Typography sx={{ p: 2 }} variant="h5" fontWeight="bold">
              Product Warnings
            </Typography>
            <Typography
              sx={{ pl: 2, pb: 2, pr: 2 }}
              dangerouslySetInnerHTML={{ __html: productWarnings }}
            />
          </>
        )}
        {productUsageAdvisory && (
          <>
            <Typography sx={{ p: 2 }} variant="h5" fontWeight="bold">
              Product Usage Advisory
            </Typography>
            <Typography
              sx={{ pl: 2, pb: 2, pr: 2 }}
              dangerouslySetInnerHTML={{ __html: productUsageAdvisory }}
            />
          </>
        )}
      </Popover>
    </div>
  );
}
