"use client";
import * as React from "react";
import { useContext } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import BasicPopover from "./BasicPopover";
import Typography from "@mui/material/Typography";
import { ExperienceObj } from "@/app/@types/experienceobj";
import SubmittedExperContext from "@/context/submittedExperContext";

/**
 * A React component that displays a single confirmed selected YEX experience.
 *
 * @remarks
 * This component displays the details of a YEX experience for the confirmation page including
 * the experience name, the experience details, as well as an image of the YEX experience.
 * @param param0 -
 * @returns - The SelectedExper React component in JSX.
 */
export default function SelectedExper({
  id,
  category,
  name,
  results,
  use,
  productWarnings,
  productUsageAdvisory,
  image,
}: ExperienceObj) {
  const qrimage = image.split(".")[0] + "-QR.png";
  const { submittedExper } = useContext(SubmittedExperContext);
  console.log("submittedExper", submittedExper);

  return (
    <Box>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
          height: "100%",
        }}
      >
        <CardHeader title={name} />
        <CardMedia
          sx={{ height: 450, width: 550, objectFit: "contain" }}
          image={"/" + image}
          title={name}
        />
        <Box sx={{ my: 1 }} />
        <CardMedia
          sx={{ height: 100, width: 100, mx: "auto" }}
          image={"/" + qrimage}
          title={name}
        />
        <CardContent>
          <Typography variant="body1">{use}</Typography>
        </CardContent>
        {!submittedExper && (
          <CardActions sx={{ alignItems: "center", justifyContent: "center" }}>
            <BasicPopover
              results={results}
              productUsageAdvisory={productUsageAdvisory}
              productWarnings={productWarnings}
            />
          </CardActions>
        )}
      </Card>
    </Box>
  );
}
